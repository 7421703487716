.login_card {
    padding-top: 20px;
    width: 500px;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    // padding: 40px;

    button {
      float: right;
    }
  }
