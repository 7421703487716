@import "~react-big-calendar/lib/css/react-big-calendar.css";

.calendar_row {
  margin: 20px;
  height: 87vh;

  .main_calendar {
    height: 81vh;
  }
}

.create_button {
  margin: 0 0 10px 0 !important;
}