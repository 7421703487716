//@table-row-hover-bg: auto;
//@table-border-color: hsl(0, 0%, 80%);

//.loading_paper {
//  width: 100%;
//  height: 87vh;
//
//  .MuiLinearProgress-root {
//    border-radius: 5px;
//  }
//}

.main_tabel {
  margin: 20px;
  height: calc(100vh - 104px);

  .tabel_container {
    //width: 95vw;
    //margin: 20px auto;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    user-select: none;
    height: calc(100vh - 104px);
    display: flex;
    flex-direction: column;

    .tabel_title {
      padding: 2px;
      font-size: 13pt;
      font-weight: bold;
    }

    .MuiBox-root {
      overflow: hidden;
    }
  }

  .tabel_table {
    //border: lightgray solid 1px;
    padding-bottom: 10px;

    .fixed.MuiTableCell-head {
      z-index: 3;
    }

    .fixed {
      position: sticky;
      left: 0;
      background-color: white;
      //box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    thead th {
      border-top: lightgray solid 1px;
      font-weight: bold;
    }

    th:nth-child(1) {
      border-left: lightgray solid 1px;
    }

    td, th {
      border-right: lightgray solid 1px;
    }

    .remove_btn {
      position: absolute;
      right: -5px;
      top: -5px;
    }
  }
}

.title {
  justify-content: space-between;
  margin-bottom: 10px;
}

.now {
  background-color: hsl(180, 80%, 90%) !important;
}

.weekend {
  background-color: hsl(100, 100%, 90%) !important;
}

.warning {
  background-color: hsl(0, 100%, 90%) !important;
}

.checked:not(.Mui-selected ) {
  background-color: hsl(100, 100%, 90%);
}

.center_modal {
  width: 100%;

  button {
    float: right;
  }
}
