.mask {
  position: absolute;
  z-index: 999;
  background: black;
  width: 100%;
  height: 100%;
  opacity: .3;
}

.loading {
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}