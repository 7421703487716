@import "~react-big-calendar/lib/css/react-big-calendar.css";

.top_paper {
  margin-bottom: 10px;
}

.order_calendar {
  margin: 20px;

  .main_calendar {
    height: 76vh;
  }

  .rbc-time-view {
    .rbc-label {
      display: none;
    }

    .rbc-time-content {
      display: none;
    }
  }
}

.create_button {
  margin: 0 0 10px 0 !important;
}

