.order_form {
  .ant-form-item {
    margin: 0;
  }

  .add_field_btn {
    margin: 0 0 10px 0;
    padding: 10px;
    text-align: center;
  }
}

.order_container {
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  height: calc(100vh - 104px);
  display: flex;
  flex-direction: column;

  table {
    margin-bottom: 10px;
  }

  //.MuiBox-root {
  //  overflow: auto;
  //}
}

.empty {
  border-left: 3px solid white !important;
}

.delivered {
  background-color: hsla(100, 100%, 50%, 0.2) !important;
  border-left: 3px solid green !important;
}

.not_agreed {
  background-color: hsla(0, 100%, 50%, 0.2) !important;
  border-left: 3px solid red !important;
}

.in_agreement {
  background-color: hsla(50, 100%, 50%, 0.2) !important;
  border-left: 3px solid yellow !important;
}
