.main_catalog {
  margin: 20px;
  height: 87vh;

  .add_btn {
    float: right;
    margin-bottom: 10px;
  }

  .MuiTableCell-head {
    font-weight: bold;
  }

  .catalog_form {
    width: 600px;
  }

  .tabel_container {
    height: 76vh;
    overflow: hidden;
  }
}