.main_sun {
  display: flex;
  justify-content: center;
}

.edit_doc_btn {
  position: absolute !important;
  z-index: 1000;
  top: 7px;
  right: 40px;
}

.title_doc {
  position: absolute;
  top: 10px;
  z-index: 1000;
  left: 20px;
}

.doc_read {
  .se-toolbar {
    text-align: right;
  }
}